import {Component, useEffect, useRef, useState, createRef} from "react";
import API from "../rsc/variables"
import WaveSurfer from 'wavesurfer.js'
import * as d3 from 'd3'
import {CircularProgress} from "@mui/material";


class Audio extends Component{
    constructor(props) {
        super(props);
        this.state = {audio: undefined,
            playing: "play_circle",
            seek: 0,
            audioReady: false,
            signals: [],
            selectedSong: "",
            localStorageLength: 0,
            loading: false,
            eda: [],
            pox: []
        }
        this.ref = createRef()
        this.event = new Event('customPlay')
        this.vertLine = null
        this.svgContainer = undefined
        this.margin = {top: 5, bottom: 5, left: 5, right: 5}
    }

    init = ()=>{
        let svgContainer = d3.select(".signalContainer")
            .append('svg')
            .attr('id', 'signals')
            .attr('width', '100%')
            .attr('height', '400px')
            .style('background', 'white')


        let rect = svgContainer.node().getBoundingClientRect()
        let rectGrp = svgContainer.append('g')
        let vertLine = rectGrp.append('rect')
            .attr('width', '2')
            .attr('height', rect.height)
            .attr('y', 0)
            .attr('fill', 'red')
            .attr('id', 'vertLine')


        d3.select("#audioWave")
            .on('click', function(e, d){

                let [x, y] = d3.pointer(e, this)
                vertLine
                    .attr('transform', `translate(0, 0)`)
                    .attr('transform', `translate(${x}, 0)`)
            })



        vertLine.on('customPlay', function(e, d){
            let x = d3.select('#audioWave > wave > wave').style('width')
            x = x.replace('px', '')
            d3.select("#vertLine")
                .attr('transform', `translate(0, 0)`)
                .attr('transform', `translate(${x}, 0)`)
        })


        svgContainer.on('click', (e)=>{
            let [x, y] = d3.pointer(e, d3.select("#signals").node())
            d3.select("#vertLine")
                .attr('transform', `translate(0, 0)`)
                .attr('transform', `translate(${x}, 0)`)

            let width = d3.select("#signals").node().getBoundingClientRect().width
            this.seekTo(x /width)
        })
        this.vertLine = vertLine
        this.svgContainer = svgContainer
        this.svgContainer
            .append('g')
            .attr('class', 'trends')

        this.svgContainer
            .append('g')
            .attr('class', 'axisContainer')
    }


    findDifferences(a, b) {
        return a.filter(item =>!b.includes(item))
    }

    updateSelection = (differences)=>{

        let selected = JSON.parse(localStorage.getItem('selected'))
        if (selected.length === 0 ) {
            this.setState({signals: [], eda:[]})
            return
        }

        if (differences > 0) {
            let fetch = []
            selected.forEach(id => {
                let index = this.state.signals.findIndex(element => element === id)
                if (index === -1) {
                    fetch.push(id)
                    this.setState((prevState) => {
                        return ({signals: [...prevState.signals, id]})
                    })
                }
            })
            this.props.songs.forEach(song=> {
                fetch.forEach(signal=>{
                    const url = `/edasignals?title=${song}`
                    let config = {
                        headers:{
                            reports: [signal]
                        }
                    }
                    API.get(url, config)
                        .then(res=>{
                            if (res.data){
                                let object = {}
                                res.data.song = song
                                object[signal] = res.data
                                this.setState((prevState)=>{
                                    return ({eda: [...prevState.eda, object]})
                                })
                            }
                        }).catch(e=>console.log(e))
                })

            })
        }
        else {
            let dif = this.findDifferences(this.state.signals, selected)
            console.log(dif)
            let index = this.state.signals.findIndex(element => element === dif[0])
            let temp = [...this.state.signals]
            temp.splice(index, 1)
            let edaTemp = [...this.state.eda]
            index = edaTemp.findIndex(signal=> {
                return Object.keys(signal)[0] === dif[0]
            })
            edaTemp.splice(index, 1)

            this.setState({signals: [...temp], eda: [...edaTemp]})
        }
    }

    handleSelection = () =>{
        if(localStorage.getItem('selected')){
            this.setState({localStorageLength: localStorage.getItem('selected').length})

    }
    }
    findMaxMin(data, field) {
        let out = []
        data.forEach(signal=>{
            out.push(signal[Object.keys(signal)[0]])
        })
        return (d3.extent(out.flat(), d=>d[field]))
    }

    plotEDA() {
        this.svgContainer.select('.trends').selectAll('*').remove()
        this.svgContainer.select('.axisContainer').selectAll('*').remove()
        if (this.state.eda.length > 0 ){
            if (this.state.selectedSong !== "")
            {
                let [min, max] = this.findMaxMin(this.state.eda, 'value')
                let rect = this.svgContainer.node().getBoundingClientRect()

                let scaleY = d3.scaleLinear()
                    .domain([min, max])
                    .range([rect.height, 0])

                this.state.eda.forEach(signal => {

                    let data = signal[Object.keys(signal)[0]]
                    let scaleTime = d3.scaleTime()
                        .domain(d3.extent(data, d => d.time))
                        .range([0, this.state.audio.getDuration()])


                    let scaleX = d3.scaleTime()
                        .domain([0, this.state.audio.getDuration()])
                        .range([0, rect.width])

                    let lineMaker = d3.line()
                        .x(d => {
                            return scaleX(scaleTime(d.time))
                        })
                        .y(d => scaleY(d.value))

                    this.svgContainer
                        .select(".trends")
                        .append('path')
                        .attr('d', data.song === this.state.selectedSong ? lineMaker(data) : "")
                        .attr('fill', 'none')
                        .attr('stroke', 'blue')

                })

                let axMkrY = d3.axisLeft(scaleY)
                    .tickSize(-(rect.right - this.margin.right * 2.5))

                let axisGroup = d3.select(".axisContainer")

                axisGroup.append('g')
                    .attr('class', 'axis')
                    .attr('transform', `translate(${rect.left + this.margin.left}, 0)`)
                    .attr('font-size', '.2em')
                    .call(axMkrY)

            }
        }
    }


    componentDidMount() {
        window.addEventListener('StorageEvent', this.handleSelection)
        this.init()
        this.setWaveSurf()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.songs !== prevProps.songs) {
            this.addSongs()
        }
        if (this.state.loading) {
            this.loading()
        }

        if (this.state.localStorageLength !== prevState.localStorageLength) {
            this.updateSelection(this.state.localStorageLength - prevState.localStorageLength)
        }

        if (this.state.audioReady) {
            this.plotEDA()

        }
    }

    getAudio = (e) =>{
        let songName = e.target.value

        if (songName === "") {
            if (this.state.audio) {
                this.state.audio.empty()
                this.setState({selectedSong: ""})
            }
            return
        }

        if (this.state.selectedSong === "" || this.state.selectedSong !== e.target.value){
            if (this.state.audio) {
                this.state.audio.empty()
            }
            const url = `/audio?title=${songName}`
            const options = {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'audio/wav'
                }
            }
            this.setState({loading: true, audioReady: false})

            API.get(url, options)
                .then(res=>{

                    const blob = new Blob([res.data], {type: 'audio/wav'})
                    this.updateWaveSurf(blob, songName)
                    this.setState({loading: false})

                }).catch(e=>console.log(e))
        }

    }

    updateWaveSurf = (blob, title)=>{
        this.state.audio.loadBlob(blob)
        this.setState({selectedSong: title})
    }

    setWaveSurf = () =>{
        if (this.state.audio === undefined){
            let wavesurfer = WaveSurfer.create({
                container: this.ref.current,
                waveColor: 'thistle',
                progressColor: 'black'
            })
            wavesurfer.on('play', ()=>{
                this.setState({playing: 'pause_circle'})
            })
            wavesurfer.on('pause', ()=>{
                this.setState({playing: 'play_circle'})

            })
            wavesurfer.on('audioprocess', ()=>{
                if (this.vertLine){
                    this.vertLine.node().dispatchEvent(this.event)
                }
            })
            wavesurfer.on('ready', ()=>{
                this.setState({audioReady: true})
            })
            this.setState({audio: wavesurfer})
        }
    }

     playPause = () =>{
        if (this.state.audio) {
            if(this.state.audio.getDuration() === 0) {
                this.setState({playing: "play_circle"})
            }
            if (this.state.audio.getDuration())
            if (this.state.audio.isPlaying()) {
                this.state.audio.playPause()
                this.setState({playing: "play_circle"})

            }
            else {
                this.state.audio.playPause()
                this.setState({playing: 'pause_circle'})
            }
        }
    }

    seekTo = (value) =>{
        if (this.state.audio){
            this.state.audio.seekTo(value)
        }
    }

    addSongs = () =>{
        return this.props.songs.map((element, i)=>
            <option key={`song_${i}`} value={element}>{element}</option>
        )
    }

    loading = () =>{
        if (this.state.loading) {
            return (
                <CircularProgress/>
            )
        }
    }

    render() {

        return(
            <>
                <div className={'inputContainer'}>
                    <label htmlFor={'audioSelector'}>Select audio:</label>
                    <select onChange={this.getAudio} id={'audioSelector'}>
                        <option value={""} key={`song_emp`}>None</option>
                        {this.addSongs()}
                    </select>
                </div>
                {this.loading()}
                <div className={'audioContainer'}>
                    <div id={'audioWave'} ref={this.ref}></div>

                    <div className={"audioIcon"}>
                        <button onClick={this.playPause} >
                            <span  className={'material-symbols-outlined'}>{this.state.playing}</span>
                        </button>
                    </div>



                </div>
                <div className={'signalContainer'}>

                </div>

            </>
        )
    }


}

export default Audio