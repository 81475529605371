import {useState, useEffect} from "react";
import API from "../rsc/variables";
import CustomSelect from "./select";

const Labels = (props)=>{
    let [titles, setTitles] = useState([])
    let [selTitle, setTitle] = useState(titles[0] || "")
    useEffect(()=>{
        API.get("/labels")
            .then((res)=> {
                let data = JSON.parse(res.data)
                let labels = []
                data.forEach(element=>{
                    labels.push(element.title)
                })
                setTitles(labels)
            })
    }, [])

    const setValue = (value) =>{
        setTitle(value)
    }

    return(
        <>
            <CustomSelect setParentValue={props.handleLabel}
                          setValue={setValue}
                          keyPrefix={'label'}
                          values={titles}
                          selectedValue={selTitle}/>
        </>
    )
}

export default Labels;