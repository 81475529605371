import {Component} from "react";
import API from "../rsc/variables"
import CustomSelect from "./select";

class Models extends Component{
    constructor(props) {
        super(props);
        this.props = props
        this.state = {models: [],
            selModel:"",
            selLabel: props.selLabel,
            selInput: props.selSignal}
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.selLabel !== "" && this.props.selSignal !== ""){
            if (this.state.model !== null && this.state.models.length === 0  && this.state.selModel === ""){
                API.get(`labels/${this.props.selLabel}/${this.props.selSignal}/models`)
                    .then((res)=>{
                        this.setState({models: res.data})
                    })
            }
            // TODO: BUG (no priority): If label changes, then the last value of prev model input is being sent

            if(this.props.selLabel !== prevProps.selLabel || this.props.selSignal !== prevProps.selSignal){
                API.get(`labels/${this.props.selLabel}/${this.props.selSignal}/models`)
                    .then((res)=>{
                        this.setState({models: res.data})
                    })
            }
        }

        if (this.props.selLabel === "" || this.props.selSignal === ""){
            if(this.props.selLabel !== prevProps.selLabel || this.props.selSignal !== prevProps.selSignal){
                this.setState(()=>{
                    this.props.handleLabel("")
                    return{
                        models: [],
                        selModel: ""
                    }
                })
            }
        }
    }

    setValue = (value) => {
        this.setState({selModel: value})
    }

    render() {
        return(
            <>
                <CustomSelect setParentValue={this.props.handleLabel}
                              setValue={this.setValue}
                              keyPrefix={'model'}
                              values={this.state.models}
                              selectedValue={this.state.selModel}/>
            </>
        );
    }
}

export default Models