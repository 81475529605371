import {Component} from "react";
import ConfMatVis from "./confMatVis";
import API from "../rsc/variables"
import {CircularProgress} from "@mui/material";

class ConfmatReports extends Component{

    constructor(props) {
        super(props);
        this.props = props
        this.state = {reports: {}}
    }


    componentDidMount() {
        const url = `/confmat/reports?confid=${this.props.confid}`
        if (localStorage.getItem(this.props.confid) !== null) {
            this.setState({reports: JSON.parse(localStorage.getItem(this.props.confid))})
            return
        }
        API.get(url)
            .then(res =>{
                let data = res.data.reports
                Object.keys(data).forEach(key=>{
                    let out ={}
                    if (Array.isArray(data[key])) {
                        if (!out.hasOwnProperty(key)) out[key]={}

                        const url = `/reports`
                        let config = {
                            headers:{
                                reports: [...data[key]]
                            }
                        }

                        API.get(url, config)
                            .then(res =>{
                                out[key]=res.data
                                this.setState((prevState, prevProp)=>{

                                    localStorage.setItem(this.props.confid, JSON.stringify({...out, ...prevState.reports}))
                                    return {
                                        reports: {...out, ...prevState.reports}
                                    }
                                })
                            })

                    }
                })

            })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.confid !== "" && this.props.confid !== prevProps.confid){
            const url = `/confmat/reports?confid=${this.props.confid}`
            API.get(url)
                .then(res =>{
                    let data = res.data.reports
                    Object.keys(data).forEach(key=>{
                        let out ={}
                        if (Array.isArray(data[key])) {
                            if (!out.hasOwnProperty(key)) out[key]={}

                            const url = `/reports`
                            let config = {
                                headers:{
                                    reports: [...data[key]]
                                }
                            }

                            API.get(url, config)
                                .then(res =>{
                                    out[key]=res.data
                                    this.setState((prevState, prevProp)=>{
                                        return {
                                            reports: {out, ...prevState}
                                        }
                                    })
                                })

                        }
                    })
                })
        }
    }


    //TODO: add after debugging
    // componentWillUnmount() {
    //     localStorage.removeItem(this.props.confid)
    // }

    render(){
        if (Object.keys(this.state.reports).length !== 4) {
            return(
                <>
                    <p>Fetching</p>
                    <CircularProgress/>
                </>
                )

        }
        else{
            return(
                <>

                    <ConfMatVis reports={this.state.reports}
                                width={200}
                                height={200}
                                elementId={this.props.elementId}
                                selection={this.props.selection}
                    />
                </>
            )
        }

    }
}

export default ConfmatReports