import {Component} from "react"
import API from "../rsc/variables"
import CustomSelect from "./select";

class InputSignal extends Component{

    constructor(props) {
        super(props);
        this.props = props
        this.state = {inputSignals:[], selSignal:"", selLabel:props.selLabel}
    }



    componentDidUpdate(prevProps, prevState, snapshot) {
        if( this.props.selLabel !== prevProps.selLabel) {
            if (this.props.selLabel === "")
            {
                this.setState(()=>{
                    this.props.handleLabel("")
                    return{
                        inputSignals: [],
                        selSignal: ""
                    }
                })
            }
            else{
                API.get(`/label?title=${this.props.selLabel}`)
                    .then((res) => {
                        let data = res.data.inputSignals
                        this.setState(() => {
                            this.props.handleLabel("")
                            return {inputSignals: data, selSignal: ""}
                        })
                    })
            }
        }
    }

    setValue = (value) =>{
        this.setState({selSignal: value})
    }

    render() {
        return (
            <>
                <CustomSelect setParentValue={this.props.handleLabel}
                              setValue={this.setValue}
                              keyPrefix={'signal'}
                              values={this.state.inputSignals}
                              selectedValue={this.state.selSignal}/>
            </>
        );
    }
}



export default InputSignal