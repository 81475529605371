import * as d3 from 'd3'
import {Component, createRef} from "react";
import Histogram from "./histogram";
import API from "../rsc/variables";

class ConfMatVis extends Component{
    constructor(props) {
        super(props);
        this.props = props
        this.margin = {top: 10, right: 10, bottom: 10, left: 10}
        this.width = this.props.width - this.margin.left - this.margin.right
        this.height = this.props.height - this.margin.top - this.margin.bottom
        this.state = {
            values: {},
            responses:{},
            responseA: "Trials",
            responseB: "",
            tpDiv: undefined,
            fpDiv: undefined,
            fnDiv: undefined,
            tnDiv: undefined,
            tpData: undefined
        }

        this.container = undefined
        this.ref = createRef()
    }


    makeBlock = (element, className, gridArea) => {
        return (
            element.append("div")
            .attr('class', className)
            .style("grid-area", gridArea)
        )
    }

    getLabels = () => {
        let response = new Set()
        Object.keys(this.props.reports).forEach(key=>{
            this.props.reports[key].forEach(report=>{
                let currReport = report.report

                Object.keys(currReport).forEach(subKey=>{

                    if (typeof(currReport[subKey]) !== "string"){
                        if (Object.keys(currReport[subKey]).length !== 0){
                            Object.keys(currReport[subKey]).forEach(value=>{

                                if (typeof(currReport[subKey][value]) === 'number'
                                    || typeof(currReport[subKey][value]) === 'boolean'
                                    || typeof(currReport[subKey][value]) === 'string') {
                                    response.add(`${subKey}-${value}-${typeof(currReport[subKey][value])}`)
                                }
                            })
                        }
                    }

                })
            })
        })

        let result = {}
        result['Trials'] = []
        Array.from(response).forEach(element=> {
                let info = element.split("-")
                let key = info[0].charAt(0).toUpperCase() + info[0].slice(1)
                if (!result.hasOwnProperty(key)) result[key] = []
                result[key].push([info[1], info[2]])
            })
        this.setState({responses: result})


    }

    init = () => {
        if (this.container === undefined) {

            let root = this.ref.current
            this.container = d3.select(root)
                //
                .attr('class', 'gridContainer')

        }
            this.getLabels()
            let gridBlockClass = 'grid-block'

            let tpdiv = this.makeBlock(this.container, gridBlockClass, "tp")

            let fndiv = this.makeBlock(this.container, gridBlockClass, "fn")

            let fpdiv = this.makeBlock(this.container, gridBlockClass, "fp")

            let tndiv = this.makeBlock(this.container, gridBlockClass, "tn")
            this.setState({tpDiv: tpdiv, fnDiv: fndiv, fpDiv: fpdiv, tnDiv: tndiv})
    }





    componentDidMount() {
            this.init()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.reports !== prevProps.reports){

            this.init()
        }
    }

    updateResponseA = ()=>{
        let out = []
        Object.keys(this.state.responses).forEach((key, i)=>{
            out.push(<option key={`${this.props.elementId}_${i}`} value={key}>{key}</option>)
        })
        return out
    }

    handleChangeResponseA = (e)=>{
        this.setState({responseA: e.target.value})
        if(e.target.value !== 'Trials') {
            this.setState({responseB: `${this.state.responses[e.target.value][0][0]}-${this.state.responses[e.target.value][0][1]}`})
        }
        else {
            this.setState({responseB: ""})
        }
    }

    updateResponseB = ()=>{
        let out = []
        let def = ""
        if (Object.keys(this.state.responses).length > 0){
            this.state.responses[this.state.responseA].forEach((key, i)=>{
                if (key===undefined){
                    return
                }
                out.push(<option key={`${this.props.elementId}_${i}`}  value={`${key[0]}-${key[1]}`}>{key[0]}</option>)
                if (i === 0){
                    def = key[0]
                }
            })
        }
        return (
            <>
                {this.state.responseA === 'Trials'?<></>:
                    <select onChange={this.handleChangeResponseB}
                            value={(this.state.responseB===def || this.state.responseB==="")?
                                def:this.state.responseB}
                    >
                        {out}
                    </select>
                }
            </>
        )
    }

    handleChangeResponseB = (e)=>{
        this.setState({responseB: e.target.value})
    }

    render(){
        return(
            <div className={'confvisContainer'}>
                <div className={'responseContainer'}>
                    <select onChange={this.handleChangeResponseA} value={this.state.responseA}>
                        {this.updateResponseA()}
                    </select>
                    {this.updateResponseB()}
                </div>
                <div ref={this.ref}>
                    <Histogram parent={this.state.tpDiv} width={"500px"} data={this.props.reports.tp}
                               groupName={"TP"}
                               responseA={this.state.responseA}
                               responseB={this.state.responseB}
                               selection={this.props.selection}
                               elementId={this.props.elementId}
                    />
                    <Histogram parent={this.state.fnDiv} width={"500px"} data={this.props.reports.fp}
                               groupName={"FP"}
                               responseA={this.state.responseA}
                               responseB={this.state.responseB}
                               selection={this.props.selection}
                               elementId={this.props.elementId}
                    />
                    <Histogram parent={this.state.fpDiv} width={"500px"} data={this.props.reports.fn}
                               groupName={"FN"}
                               responseA={this.state.responseA}
                               responseB={this.state.responseB}
                               selection={this.props.selection}
                               elementId={this.props.elementId}
                    />
                    <Histogram parent={this.state.tnDiv} width={"500px"} data={this.props.reports.tn}
                               groupName={"TN"}
                               responseA={this.state.responseA}
                               responseB={this.state.responseB}
                               selection={this.props.selection}
                               elementId={this.props.elementId}
                    />
                </div>
            </div>
        )
    }


}



export default ConfMatVis