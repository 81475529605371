import {useState} from "react";


const CustomSelect = (props) => {
    const keyPre = props.keyPrefix
    const values = props.values
    const selValue = props.selectedValue

    const handleChange = (e) =>{
        props.setValue(e.target.value)
        props.setParentValue(e.target.value)
    }

    function renderValues(values){
        if (values !== null){
            let out = []
            values.map((element, idx)=>{
                 out.push(<option key={`${keyPre}_${idx}`} value={element}>{element}</option>)
            })
            return out
        }
        else
            return ""
    }
    return(
        <select onChange={handleChange} value={selValue} disabled={selValue !== ""}>
            <option value={""} key={`${keyPre}_emp`}>None</option>
            {renderValues(values)}
        </select>
    )
}

export default CustomSelect