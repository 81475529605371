import Labels from "./labels";
import InputSignal from "./signals";
import Models from "./models";
import Songs from "./songs";
import {useState} from "react";
import {ConfMatIDSong} from "./confmat";
import ConfmatVis from "./confmatVisContainer";
import "../css/analysisList.css"


const AnalysisHolder = (props) =>{

    const [selLabel, setLabel] = useState("")
    const [selSignal, setSignal] = useState("")
    const [selModel, setModel] = useState("")
    const [selSongA, setSongA] = useState("")
    const [selSongB, setSongB] = useState("")
    const [selConfID, setConfID] = useState("")



    const handleLabel = (label) => {
        setLabel(label)
    }
    const handleSignal = (signal) => {
        setSignal(signal)
    }

    const handleModel = (model) => {
        setModel(model)
    }

    const handleSongA = (song) => {
        setSongA(song)
        props.songs(song)
    }

    const handleSongB = (song) => {
        setSongB(song)
        props.songs(song)
    }

    const handleConfID = (confid) => {
        setConfID(confid)
    }

    return(
        <div key={props.elementId} >

            <form className={"formContainer"}>
                <div className={"inputContainer"}>
                    <label htmlFor={'labels'}>Analyzed labels: </label>
                    <Labels id={'labels'} handleLabel={handleLabel}/>
                </div>

                <div className={"inputContainer"}>
                    <label htmlFor={'inputs'}>Model's input: </label>
                    <InputSignal id={'inputs'} selLabel={selLabel} handleLabel={handleSignal}/>
                </div>

                <div className={"inputContainer"}>
                    <label htmlFor={'models'}>Model Architectures: </label>
                    <Models id={"models"} selLabel={selLabel} selSignal={selSignal} handleLabel={handleModel}/>
                </div>

                <div className={"inputContainer"}>
                    <label htmlFor={'songA'}>Selected Song1: </label>
                    <Songs id={'songA'}
                           selLabel={selLabel}
                           selSignal={selSignal}
                           selModel={selModel}
                           handleLabel={handleSongA}
                           firstSong={true}
                    />
                </div>

                <div className={'inputContainer'}>
                    <label htmlFor={'songB'}>Selected Song2: </label>
                    <Songs id={'songB'}
                           selLabel={selLabel}
                           selSignal={selSignal}
                           selModel={selModel}
                           handleLabel={handleSongB}
                           SongA={selSongA}
                    />
                </div>

                <ConfMatIDSong id={'confID'}
                               selLabel={selLabel}
                               selSignal={selSignal}
                               selModel={selModel}
                               songA={selSongA}
                               songB ={selSongB}
                               handleLabel={handleConfID}
                />
            </form>
            <ConfmatVis confid={selConfID}
                        elementId={props.elementId}
                        selection={props.selection}
                        />

        </div>
    )
}

export default AnalysisHolder