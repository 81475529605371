import {Component, createRef} from "react";
import API from '../rsc/variables'
import ConfmatReports from "./ConfmatReports";


class ConfMatVisContainer extends Component{
    constructor(props) {
        super(props);
        this.props = props
        this.state = {hasSwapped: false, swappedConfid: "", includeSwapped: false}
        this.ref = createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.confid !== "" && this.props.confid !== prevProps.confid){
            const url = `/confmat/swapped?confid=${this.props.confid}`
            API.get(url)
                .then(res =>{
                    const data = res.data
                    if (data.id !== null){
                        this.setState({
                            hasSwapped: true,
                            swappedConfid: data.id
                        })
                    }
                })
        }


    }

    handleCheck = ()=>{
        if (this.ref.current.checked){
            const url = `/confmat/swapped?confid=${this.props.confid}`
            API.get(url)
                .then(res =>{
                    const data = res.data
                    if (data.id !== null){
                        this.setState({
                            swappedConfid: data.id,
                            includeSwapped: true
                        })
                    }
                })
        }
        else
            this.setState({includeSwapped: false, swappedConfid: ""})
    }

    HasSwapped = () =>{
        if (this.state.hasSwapped)
            return(
                <div>
                    <label htmlFor={'incSwap'}>Include second fold</label>
                    <input ref={this.ref} type={'checkbox'} id={'incSwap'} onClick={this.handleCheck} defaultChecked={false}/>
                </div>
            )
    }

    renderConfMat = ()=>{
        if (this.props.confid !== ""){
            return (
                <div>
                    <ConfmatReports confid={this.props.confid}
                                    elementId={`${this.props.elementId}`}
                                    selection={this.props.selection}
                                    />
                    {this.state.includeSwapped && <ConfmatReports confid={this.state.swappedConfid}
                                                                  elementId={`${this.props.elementId}`}
                                                                  selection={this.props.selection}
                                                                  />}
                </div>
            )
        }
    }

    render() {
        return(
            <>
                <this.HasSwapped/>
                {this.renderConfMat()}
            </>

        )
    }


}

export default ConfMatVisContainer