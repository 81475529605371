import {Component} from "react";
import AnalysisHolder from "./components/analysisHolder";
import "./css/analysisList.css"
import uuid from 'react-uuid'
import Audio from "./components/Audio";


class App extends Component {
    constructor(props) {
        super(props);
        this.state = {analysisList: [], counter: 0, ids: [], selectedSongs: [], signals: []}
    }


    componentDidMount() {
        this.componentCleanup()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.selectedItems !== prevProps.selectedItems) {
            return({analysisList: [...prevState.analysisList]})
        }
    }

    onAddButton = () => {
         this.setState((prevState) => {
             let id = uuid()
             let newIds = [...prevState.ids, id]

             return ({
                 analysisList: [...prevState.analysisList, (<AnalysisHolder key={id}
                                                                            elementId={id}
                                                                            selection={this.handleSelection}
                                                                            songs={this.updateSelectedSongs}
                 />)
                 ],
                 counter: prevState.counter + 1,
                 ids: newIds
             })
         })
     }

    onDeleteButton = ()=>{
        this.setState((prevState)=>{

            if (prevState.counter - 1 === 0) {
                localStorage.removeItem('selected')
            }



            return({
                analysisList: prevState.analysisList.filter((_, idx) => idx !== prevState.analysisList.length - 1),
                counter: prevState.counter - 1 === 0?0:prevState.counter - 1,
                ids: prevState.ids.filter((_, idx) => idx !== prevState.ids.length - 1)
            })

        })
    }

    handleSelection = (selectedId, elementId)=>{

        let selectedList = JSON.parse(localStorage.getItem('selected'))
        if (selectedList === null) selectedList = []
        let index = selectedList.findIndex(element=>element === selectedId)
        if (index === -1) {
            selectedList.push(selectedId)
        }
        else {
            selectedList.splice(index, 1)
        }
        localStorage.setItem('selected', JSON.stringify(selectedList))

        // Creating StorageEvent that we call in histogram component.
        let evt = new StorageEvent('StorageEvent', {bubbles: false, cancelable: false,
            storageArea: window.localStorage, key:'selected', oldValue:'oldValue', newValue:'newValue', url:null})
        window.dispatchEvent(evt)
    }

    updateSelectedSongs = (selectedSong)=>{
        this.setState((prevState)=>{
            let index = prevState.selectedSongs.findIndex(song=> song === selectedSong)
            if (index === -1) {
                return ({selectedSongs: [...prevState.selectedSongs, selectedSong]})
            }
            })
    }

    componentCleanup = ()=>{
        localStorage.removeItem('selected')
    }

    componentWillUnmount() {
        this.componentCleanup()
        window.removeEventListener('beforeunload', this.componentCleanup)
    }

    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <h1>EIM Analysis</h1>
                </header>
                <main>
                    <div className={'analysis'}>
                        <button onClick={this.onAddButton}>Add</button>
                        <button onClick={this.onDeleteButton}>Delete</button>
                        <div className={"analysisContainer"}>
                            {this.state.analysisList}
                        </div>
                    </div>
                    <div className={'audio'}>
                        <h2>Signal visualizer</h2>
                        <Audio songs={this.state.selectedSongs}/>
                    </div>

                </main>
            </div>
        );
    }
}

export default App;
