import {Component} from "react";
import API from "../rsc/variables"
import CustomSelect from "./select";

class Songs extends Component{

    constructor(props) {
        super(props);
        this.props = props
        this.state = {
            songList: [],
            selSong: "",
            selLabel: this.props.selLabel,
            selSignal: this.props.selSignal,
            selModel: this.props.selModel
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.selModel !== ""){
            if (this.state.songList.length === 0){
                const url = `/labels/${this.props.selLabel}/${this.props.selSignal}/${this.props.selModel}/songs`
                API.get(url)
                    .then((res)=>{
                        if(this.props.firstSong){
                            this.setState({songList: Object.keys(res.data).sort()})
                        }
                    })
            }
        }
        if(this.props.selModel === ""){
            if(this.props.selModel !== prevProps.selModel){
                this.setState(()=>{
                    this.props.handleLabel("")
                    return{
                        songList: [],
                        selSong: ""
                    }
                })
            }
        }

        if (this.props.SongA !== prevProps.SongA){
            const url = `/labels/${this.props.selLabel}/${this.props.selSignal}/${this.props.selModel}/songs`
            API.get(url)
                .then((res)=>{

                        this.setState({songList: res.data[this.props.SongA]})
                })
        }

        // if(this.props.exclude !== prevProps.exclude){
        //     this.setState({
        //         songList: prevState.songList.filter(e=>e !== this.props.exclude)
        //     })
        // }
    }

    setValue = (value)=>{
        this.setState({selSong: value})
    }
    render() {
        return(
            <>
                <CustomSelect setParentValue={this.props.handleLabel}
                              setValue={this.setValue}
                              keyPrefix={'song'}
                              values={this.state.songList}
                              selectedValue={this.state.selSong}/>
            </>
        )
    }
}

export default Songs