const axios = require('axios')

const getBaseUrl = () => {
    let url;
    switch(process.env.NODE_ENV) {
        case 'production':
            url = process.env.REACT_APP_API_URL;
            break;
        case 'development':
        default:
            url = process.env.REACT_APP_API_URL;
    }

    return url;
}

export default axios.create({
    baseURL: getBaseUrl(),
});