import {Component} from "react";
import {CircularProgress} from "@mui/material";
import API from "../rsc/variables"
class ConfMatIDSong extends Component{
    constructor(props) {
        super(props);
        this.props = props
        this.state = {confid: ""}
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.songB !== ""){
            if (this.state.confid === ""){
                const url = `/labels/${this.props.selLabel}/${this.props.selSignal}/${this.props.selModel}/confid/song?song1=${this.props.songA}&song2=${this.props.songB}`
                API.get(url)
                    .then(res=>{
                        this.setState(() =>{
                            this.props.handleLabel(res.data.id)
                            return({
                                confid: res.data.id
                            })
                        })
                    })
            }
        }
    }

    GetConfid = () => {
        if (this.props.songB === ""){
            return <p></p>
        }
        if (this.props.sondB !== ""  && this.state.confid === "")

            return (
                <>
                    <p>Searching for the Confusion Matrix result based on input! This process can take upto 9 seconds</p>
                    <CircularProgress/>
                </>
            )
    }

    render(){
        return(
            this.GetConfid()
        )
    }
}

export {ConfMatIDSong}