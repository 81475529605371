import React, {Component} from "react"
import * as d3 from 'd3'
import cancelableClick from "./cancelable-click"

class Histogram extends Component {
    constructor(props) {
        super(props);
        this.state = {selected: [], localStorageLength: 0}
        this.margin = {top: 3, bottom: 3, left: 3, right: 3}
        this.container = undefined
    }

    _topTransform = (selected)=>{
        selected
            .attr('transform', `matrix(0.9, 0, 0, 0.9, ${20}, 0)`)
        return selected
    }

    init = () => {
        if (this.props.parent !== undefined) {
            let root = this.props.parent

            if (this.container === undefined) {
                this.container = d3.select(root.node())
                    .append("svg")
                    .attr('class', 'svgElement')
                    .attr('width', "98%")
                    .attr('height', "98%")
            }

            let rect = this.container.node().getBoundingClientRect()
            let category=this.props.responseA.toLowerCase()
            let itemInfo = this.props.responseB.split("-")
            let itemType = itemInfo[1]
            let item = itemInfo[0].toLowerCase()


            // Creating collision

            let colorScheme = () =>{
                switch (this.props.groupName){
                    case "TP":
                        return 0
                    case "FP":
                        return 1
                    case "FN":
                        return 2
                    case "TN":
                        return 3
                }

            }

            if (this.props.responseB === "") {

                d3.select(root.node())
                    .append('div')
                    .attr('class', 'infoTextContainer')
                    .append('div')
                    .append('p')
                    .html(`Count: ${this.props.data.length}`)

                d3.select(root.node()).selectAll("svg, div").lower()

                this.initCollision(this.container, this.props.data,  rect.width/2, rect.height / 2, rect.width,
                    4, colorScheme())

                let textGroup = this.container.append('g')
                    .attr('class', 'plotTextOpec')
                    .attr('transform', `matrix(3, 0, 0, 3, ${rect.width / 2 - 25}, ${rect.height /2})`)
                    .append('text')
                    .text(`${this.props.groupName}`)
                return
            }

            // Creating bin based on type
            let scaleX = undefined
            let minMax
            let bin
            let binColors
            let isString = false
            let keyValue = {}


            if (itemType === 'string') {
                isString = true
                let count = 1;
                this.props.data.forEach(obj => {
                    if(!keyValue.hasOwnProperty(obj.report[category][item])){
                        keyValue[obj.report[category][item]] = count
                        count += 1
                    }
                })

                this.props.data.forEach(obj => {
                    obj.report[category][`${item}-idx`] = keyValue[obj.report[category][item]]
                })

                minMax = [1, count===2?3:count]

                scaleX = d3.scaleBand()
                    .domain(Array.from({length: minMax[1] - 1}, (_,idx)=>idx+1))
                    .range([this.margin.left, rect.width - this.margin.right])
                    .padding(.2)


                binColors = d3.scaleOrdinal().domain(Array.from({length: minMax[1]}, (_,idx)=>idx+1))
                    .range(d3.schemeCategory10)

                bin = d3.bin()
                bin.domain([1, count])
                bin.thresholds(Array.from({length: count}, (_, idx)=>idx+1))
                bin.value(d=>d.report[category][`${item}-idx`])
            }
            else {

                minMax = d3.extent(this.props.data, d=> {
                    return d.report[category][item] === undefined ? 0 : d.report[category][item]
                })
                // numeric
                // For age create bins automatically.
                if (item === 'age') {
                    scaleX = d3.scaleLinear()
                        .domain(minMax)
                        .range([this.margin.left, rect.width - this.margin.right])
                        .nice()

                    binColors = d3.scaleOrdinal()
                        .range(d3.schemePaired)

                    bin = d3.bin()
                    bin.domain(scaleX.domain())
                    bin.value(d=>d.report[category][item])
                }
                else {
                    scaleX = d3.scaleBand()
                        .domain(Array.from({length: minMax[1]}, (_,idx)=>idx+1))
                        .range([this.margin.left, rect.width - this.margin.right])
                        .padding(.2)

                    binColors = d3.scaleOrdinal()
                        .domain(Array.from({length: minMax[1]}, (_,idx)=>idx+1))
                        .range(d3.schemeCategory10)

                    let numTicks = minMax[1] + 1

                    bin = d3.bin()
                    bin.domain([minMax[0], minMax[1]])
                    bin.thresholds(Array.from({length: numTicks}, (_, idx)=>idx+1))
                    bin.value(d=>d.report[category][item])
                }
            }


            let buckets = bin(this.props.data)
            let maxLength = d3.max(buckets.map(element=>element.length))

            const scaleY = d3.scaleLinear().domain([0, maxLength])
                .range([rect.height , this.margin.top])


            let group = this.container
                .append('g')
                .attr('class', 'binContainer')
            group = this._topTransform(group)

            //Handling events
            const cc = cancelableClick( 10, 600)

            let toolTip = d3.select(root.node())
                .append('div')
                .attr('class', "tooltipContainer")


            group.selectAll('rect')
                .data(buckets)
                .join('rect')
                .attr("x", d=>{
                    return (scaleX(d.x0) | 0)
                })
                .attr('y', d=>scaleY(d.length))
                .attr('height', d=> {
                    return rect.height - scaleY(d.length)
                })
                .attr('width', (d, index, elements)=>{
                    if (item !== 'age')  return scaleX.bandwidth()
                    else {
                        return ((scaleX(d.x1)|0) - (scaleX(d.x0)|0))
                    }
                })
                .attr('fill', d=>binColors(d.x0))
                .on('mouseover', function(e, d){
                    let [x, y] = d3.pointer(e, window)
                    toolTip
                        .style('left', `${x+10}px`)
                        .style('top', `${y}px`)
                        .style('visibility', `visible`)
                        .html(`${item}: ${d[0].report[category][item]}<br/>length: ${d.length}`)
                })
                .on('mouseout', function(){
                    toolTip.style('visibility', 'hidden')
                })
                .call(cc)

            cc.on('dblclick',  (e, d)=>{
                const backdrop = d3.select(root.node())
                    .append("div")
                    .attr('class', 'backdrop')

                const popDiv = backdrop
                    .append("div")
                    .attr('id', "popdiv")
                    .attr('class', 'popupStyle')

                popDiv.append("span")
                    .attr('class', 'material-icons')
                    .style('cursor', 'pointer')
                    .style('margin', '15px, 0')
                    .text('cancel')
                    .on('click', (e, d)=>{

                        d3.select(backdrop.node()).selectAll('*').remove()
                        d3.select(backdrop.node()).remove()
                })

                const popSvg = popDiv.append('svg')
                    .attr('width', '98%')
                    .attr('height', '98%')

                let rect = popSvg.node().getBoundingClientRect()

                this.initCollision(
                    popSvg,
                    d,
                    rect.width/2,
                    rect.height/2,
                    rect.width,
                    8,
                    colorScheme())

            })


            // making axis
            let axMkry = d3.axisLeft(scaleY)
                .tickSize(-(rect.width))

            let axMkrX = d3.axisBottom(scaleX)

            if (isString) {
                axMkrX.tickValues(scaleX.domain())
                    .tickFormat((d)=>Object.keys(keyValue).find(key=>keyValue[key] === d))
            }
            if(item === 'age') {
                axMkrX.ticks(4)
            }

            if (this.container.select('.axisContainer').empty()){
                let axisGroup = this.container.append('g')
                    .attr('class', 'axisContainer')

                this._topTransform(axisGroup)

                let axisY = axisGroup.append('g')
                    .attr('class', 'axis')
                    .call(axMkry)

                let axisX = axisGroup.append('g')
                    .attr('class', 'axis')
                    .attr('transform', `translate(0, ${rect.height - this.margin.bottom})`)
                    .call(axMkrX)

                if (item === 'nationality') {
                    axisX.attr('font-size', '.2em')
                }


                //Making text
                let textGroup = this.container.append('g')
                    .attr('class', 'plotText')
                    .attr('transform', `matrix(3, 0, 0, 3, ${rect.width / 2 - 25}, ${rect.height /2})`)
                    .append('text')
                    .text(`${this.props.groupName}`)
            }






        }
    }

    initCollision = (parentNode, data, xPos, yPos, width, pointRadius=3, colorScheme=0, include=true) => {
        //make circles
        const colorSchemes = [d3.schemePuBu, d3.schemeYlGn,d3.schemeRdBu, d3.schemeGnBu, d3.schemeBuGn]


        if (include)
        {
            let circleColors = d3.scaleOrdinal().range(colorSchemes[colorScheme][9])

            //Making simulation for putting bin elements into the allocated space
            const simulation = d3.forceSimulation()
                .force('charge', d3.forceManyBody().strength(-1))
                .force('x', d3.forceX().x(xPos).strength(1))
                .force('y', d3.forceY().y(yPos).strength(1))
                .force('collision', d3.forceCollide().radius(pointRadius + 1).iterations(5))


            simulation.nodes(data)
            let binContents = parentNode
                .append('g')
                .attr('class', 'binContents')

            //binContents = this._topTransform(binContents)

            binContents.selectAll('circle')
                .data(simulation.nodes())
                .enter()
                .append('circle')
                .attr('fill', (d, i)=>circleColors(i))
                .attr('r', pointRadius)
                .attr('class', (d)=>{
                    let isSelected = this.state.selected.findIndex(item=> {
                        let userId = Object.keys(item)[0]
                        return userId === d.report['user_id']
                    })
                    return isSelected === -1?"binItem":"selectedItem"
                })
                .on('click', (e, d) => {
                    this.props.selection(d.report['user_id'], this.props.elementId)
                    this.setState((prevState, prevProp)=>{
                        if (prevState.selected.length === 0) {
                            const userId = d.report['user_id']
                            d3.select(e.target)
                                .attr('class', 'selectedItem')
                            return ({selected: [userId]})
                        }
                        else {
                            const itemIdx = prevState.selected.findIndex(item=> {
                                return item === d.report['user_id']
                            })
                            if (itemIdx === -1) {
                                let item = d.report['user_id']
                                d3.select(e.target)
                                    .attr('class', 'selectedItem')
                                return ({selected: [...prevState.selected, item]})
                            }
                            else {

                                let tmp = [...prevState.selected]
                                tmp.splice(itemIdx, 1)
                                d3.select(e.target)
                                    .attr('class', 'binItem')
                                return ({selected: tmp})
                            }
                        }
                    })

                })

            simulation.on('tick', () =>{
                binContents.selectAll('circle')
                    .attr('cx', d=> {
                        return d.x
                    })
                    .attr('cy', d=>{
                        return d.y
                    })
            })

            // TODO: add brushing if have time
            // Link to example https://www.d3indepth.com/interaction/
            // let brush = d3.brush()
            // binContents.call(brush)


        }
    }

    updateArray = (selected)=> {
        let dataArray = this.props.data.map(item => item.report['user_id'])
        let intersection = this.findIntersection(dataArray, selected)
        this.setState((prevState) => {
            if (this.container !== undefined) {
                this.container.selectAll('circle')
                    .data(this.props.data)
                    .join('circle')
                    .attr('class', d => {
                        return intersection.includes(d.report['user_id']) ? 'selectedItem' : "binItem";
                    })
            }

            // differences between previews state
            let differences = this.findDifferences(intersection, prevState.selected)
            return {selected: [...prevState.selected, ...differences]}
        })
    }

    handleLocalStorage = (e)=>{
        if (window.localStorage.getItem(e.key)){
            let selected = window.localStorage.getItem(e.key).length
            if (selected !== this.state.localStorageLength){
                this.setState({localStorageLength: selected})
            }
        }
    }

    componentDidMount = () => {
        window.addEventListener('StorageEvent', this.handleLocalStorage)

        if (localStorage.getItem('selected'))
        {
            this.setState({localStorageLength: JSON.parse(localStorage.getItem('selected')).length})
        }

        this.init()
    }

    findIntersection(a, b) {
        return a.filter(item =>b.includes(item))
    }

    findDifferences(a, b) {
        return a.filter(item =>!b.includes(item))
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {

        if(this.props.parent !== prevProps.parent ||
            this.props.responseA !== prevProps.responseA ||
            this.props.responseB !== prevProps.responseB){

            //TODO If have time change this to update design pattern, no need to remove everything
            d3.select(this.props.parent.node()).selectAll('*').remove()
            this.container = undefined

            this.init()

            if (localStorage.getItem('selected')) {
                let selected = JSON.parse(localStorage.getItem('selected'))
                this.updateArray(selected)
                this.setState({localStorageLength: selected.length})
            }
            }

        if ( this.state.localStorageLength !== prevState.localStorageLength){
                if (localStorage.getItem('selected')) {
                    this.updateArray(JSON.parse(localStorage.getItem('selected')))
                }

        }


    }

    componentWillUnmount() {
        // this.state.selected.forEach(element=>{
        //     this.props.selection(element, this.props.elementId)
        // })
    }

    render() {
        return (
            <>
            </>
        );
    }
}

export default Histogram;